import React from 'react'
import Seo from '../../../components/Seo'
import BigTitle from '../../../components/BigTitle'
import DefaultSection from '../../../components/DefaultSection'
import Article from '../../../components/Article'
import TeaserSection from '../../../components/TeaserSection'
import TeaserHeader from '../../../components/TeaserHeader'
import IndividualContactSection from '../../../components/IndividualContactSection'


const Page = () => {

  return (
    <React.Fragment>
      <Seo title="Berufspraktikum Informatik"
           description="Du bist auf der Suche nach einem spannenden Job in einem innovativen IT-Unternehmen? Bewirb Dich jetzt für einen Platz als Praktikant und sammle wertvolle Erfahrungen!"/>
      <BigTitle title="Stellenangebot" imageRelativePath={'Titelbild_Karriere.jpg'} className="mask-black"
                textInverted={true}
                menuInverted={true} logoInverted={true}>Du bist auf der Suche nach einem
        spannenden Praktikum in einem innovativen IT-Unternehmen? Dann bist Du hier genau richtig.</BigTitle>

      <DefaultSection>
        <Article>
          <h1>Berufspraktikum Informatik</h1>
          <br/>
          <h2>Unsere Themen</h2>
           <p>
             <ul>
               <li>SW-Analytics</li>
               <li>Intelligente Spam-Abwehr</li>
               <li>Konferenzmanagement</li>
             </ul>
           </p>
          <p>
             Wir suchen Studierende, die gerne Software entwickeln und in diesem Bereich schon Erfahrungen vorweisen können. Wir bieten Projekte mit modernen Technologien (Spring Boot, Angular, React) zu Themen, die uns die Arbeit erleichtern oder in den R&D-Bereich fallen, und damit eine Anknüpfung zu einer Abschlussarbeit bieten.
          </p>
          <p>
            <ul>
              <li>Beginn des Praktikums: Jederzeit</li>
              <li>Vergütung: 1.200 EUR pro Monat</li>
            </ul>
          </p>
          <p>Diese Themen sind nicht unsere tagtägliche Arbeit, sondern interne Projekte, die wir für Dich vorbereitet haben. Unsere Schwerpunkte liegen in der Architekturberatung und in der Entwicklung moderner Geschäftsanwendungen.

            Wenn Du mehr über uns erfahren möchtest, schau Dich gerne auf unsere Webseite um. Zum Beispiel findest Du unter Referenzen am Ende dieser Seite spannende Projekte, die wir bei unseren Kunden zum Erfolg bringen durften.
          </p>
        </Article>
      </DefaultSection>

      <TeaserSection className="bg-level-1" anchor="bewerben">
        <TeaserHeader title="Worauf wartest Du noch?">
          <p>
            Sende uns gerne Deine Bewerbung oder melde Dich bei uns, wenn Du Fragen hast.
          </p>
          <p>Bitte beachte, dass Bewerbungen über die E-Mail-Dienste
            Outlook und Gmail aus technischen Gründen ggf. nicht bei uns ankommen.</p>
          <p className="mt-5">
            <a className="btn btn-outline-black" href="mailto:jobs@buschmais.com"
               title="Jetzt per Mail bewerben">Jetzt per Mail bewerben</a>
          </p>
        </TeaserHeader>
      </TeaserSection>

      <IndividualContactSection personKey={'christiane'} email={'jobs@buschmais.com'} tel={'+49 351 320923 11'}/>

    </React.Fragment>
  )
}

export default Page
